import React from "react";
import UseData from "../../Hooks/UseData";
import Footer from "../../Share/Footer";
import "../../Share/Style.css";
import SliderCommon from "../../Share/SliderCommon";
import AboutCard from "./AboutCard";
import HomeCard from "../../Share/HomeCard";
import PageTitle from "../../Share/PageTitle";

const AboutTwo = () => {
  const { local, serviceArray } = UseData();

  return (
    <section>
      <PageTitle title="About"></PageTitle>
      {/* End pagetitle */}

      <div className=" lg:rounded-2xl bg-white dark:bg-[#111111]">
        <div data-aos="fade">
          <div className=" pt-12 md:py-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
            {/* About page title */}
            <h2 className="after-effect after:left-[270px] lg:block hidden">
              Hi, I'm Kevin.
            </h2>
            <div className="lg:hidden">
              {/* Sidebar personal information for mobile devices */}
              <HomeCard />
              <div className=" md:gap-10 mb-12 md:pt-[30px] items-center lg:hidden ">
                <h2 className="after-effect after:left-52 mb-5">Hi, I'm Kevin.</h2>
                <div className="col-span-12 space-y-2.5">
                  <div className="lg:mr-16">
                    <p className="text-gray-lite  dark:text-color-910 leading-7">
                      I'm a Technology Director from the Chicago area with many
                      years of experience in DevOps, Test Automation, Technology
                      Management, and Leadership. I enjoy and excel at building
                      teams, platforms, and processes to solve problems for
                      technology organizations.
                    </p>
                    <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                      I've previously worked on technology teams at Stratanet.net, Encyclopædia Britannica,
                      Leapfrog Online (now iProspect), and Legacy.com, giving me over 20 years
                      of experience specifically in Software Infrastructure and Test Automation. The second
                      half of my career also includes a lot of hiring, training, and people leadership.
                      'Expert' is maybe too strong of a word, but I would certainly say that I've excelled
                      at each one of these skills. Read below for more background on my skills, and click
                      the Experience tab for a full rundown of my proudest accomplishments.
                    </p>

                    {/* <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                    I'm currently available and looking for a new full-time role
                    where my skills and experience will be a good match. If you're
                    in the market for someone to help you with testing, infrastructure,
                    hiring, onboarding, or people leadership, let's talk!
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:grid grid-cols-12 md:gap-10 pt-4 md:pt-[30px] items-center hidden ">
              <div className="col-span-12 space-y-2.5">
                <div className="lg:mr-16">
                  <p className="text-gray-lite  dark:text-color-910 leading-7">
                    I'm a Technology Director from the Chicago area with many
                    years of experience in DevOps, Test Automation, Technology
                    Management, and Leadership. I enjoy and excel at building
                    teams, platforms, and processes to solve problems for
                    technology organizations.
                  </p>
                  <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                    I've previously worked on technology teams at Stratanet.net, Encyclopædia Britannica,
                    Leapfrog Online (now iProspect), and Legacy.com, giving me over 20 years
                    of experience specifically in Software Infrastructure and Test Automation. The second
                    half of my career also includes a lot of hiring, training, and people leadership.
                    'Expert' is maybe too strong of a word, but I would certainly say that I've excelled
                    at each one of these skills. Read below for more information on my skills, and click
                    the Experience tab for a full rundown of my proudest accomplishments.
                  </p>
                  {/* <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                    I'm currently available and looking for a new full-time role
                    where my skills and experience will be a good match. If you're
                    in the market for someone to help you with testing, infrastructure,
                    hiring, onboarding, or people leadership, let's talk!
                    </p> */}
                </div>
              </div>
            </div>
          </div>
          {/* End about descriptions */}

          <div className="  pb-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
            <h3 className="text-[35px] dark:text-white font-medium pb-5">
              What am I good at?
            </h3>
            <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2 ">
              {/* Experience information  */}

              {serviceArray.map((item) => (
                <AboutCard key={Math.random()} item={item} local={local} />
              ))}
            </div>
          </div>
          {/* service provide end */}

          {/* <div className="px-2 sm:px-5 md:px-10 lg:px-14 "> */}
          {/* Slick Slider call here  */}
          {/* <SliderCommon /> */}
          {/* </div> */}

          {/* Common Footer call here */}
          <Footer bg={"#FFFF"} />
        </div>
      </div>
    </section>
  );
};

export default AboutTwo;
