import React from "react";
import { MdOutlineBusinessCenter, MdOutlineSchool } from "react-icons/md";
import UseData from "../../Hooks/UseData";

const ResumeCardTwo = () => {
  const { educationArray, experiencesArray } = UseData();
  const { local } = UseData();

  return (
    <>
      {/* <div> */}
      {/* <div className="flex items-center space-x-2 mb-4">
          <div className="text-6xl text-[#F95054]">
            <MdOutlineBusinessCenter />
          </div>
          <h4 className="text-5xl dark:text-white font-medium"> Education </h4>
        </div> */}
      {/* end flex */}

      {/* {educationArray.map((item) => (
          <div
            className="py-4 pl-5 pr-3 space-y-2 mb-6 rounded-lg  dark:border-[#212425] dark:border-2"
            style={{
              background: `${local === "dark" ? "transparent" : item?.bg}`,
            }}
            key={item.id}
          >
            <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">
              {item.date}
            </span>
            <h3 className="text-xl dark:text-white"> {item.title} </h3>
            <p className="dark:text-[#b7b7b7]">{item.place}</p>
          </div>
        ))} */}
      {/* </div> */}

      <div>
        {/* <!-- Experience contain --> */}
        <div className="flex items-center space-x-2 mb-4">
          <div className="text-6xl text-[#F95054]">
            <MdOutlineBusinessCenter />
          </div>
          <h4 className="text-5xl dark:text-white font-medium">Previous Roles</h4>
        </div>

        {experiencesArray.map(
          (item) => (
            <div
              className="py-4 pl-5 pr-3 space-y-2 mb-6 rounded-lg  dark:border-[#212425] dark:border-2"
              style={{
                background: `${local === "dark" ? "transparent" : item?.bg}`,
              }}
              key={item.id}>
              <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">
                {item.date}
              </span>
              <h3 className="text-xl dark:text-white"> {item.title} </h3>
              <p className="dark:text-[#b7b7b7]">{item.place}</p>
              <br />
              {/* <span className="text-xs text-gray-lite dark:text-[#b7b7b7]"> */}
              <span className="text-tiny text-gray-lite dark:text-white">
                Top Accomplishments:
              </span>
              <ul className="list-disc pl-8">
                {item.desc.map((accomplishment) => (
                  <div key={accomplishment}>
                    <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">
                      <li>{accomplishment}</li>
                    </span>
                  </div>
                ))}
              </ul>
            </div>
          )
        )}



        <div className="py-4 pl-5 pr-3 space-y-2 mb-6 rounded-lg  dark:border-[#212425] dark:border-2" style={{
          background: `${local === "dark"}`,
        }} key="various_companies">
          <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">
            Pre-2006
          </span>
          <h3 className="text-xl dark:text-white"> Retail, Customer Service, and Technical Support </h3>
          <p className="dark:text-[#b7b7b7]">Various Companies</p>
          <br />
          {/* <span className="text-xs text-gray-lite dark:text-[#b7b7b7]"> */}
          <span className="text-tiny text-gray-lite dark:text-white">
            The QA Engineer role at Britannica was effectively the beginning of
            my engineering and management career. Prior to this, I worked in retail sales
            and phone-based customer service and technical support. By taking on extra projects
            beyond my day-to-day responsibilities, I grew my technical and people skills, putting
            myself on the path to what has become an incredibly rewarding career.
          </span>
        </div>
      </div>
    </>
  );
};

export default ResumeCardTwo;
